import { IConfiguration } from './IConfigService'

declare global {
  interface Window {
    _env_: any
  }
}
class ConfigService {
  private configuration: IConfiguration

  constructor() {
    const runtimeConfiguration = (window as any).APP_CONFIGURATION
    const compiletimeConfiguration: IConfiguration = {
      REACT_APP_API_URL: window._env_?.REACT_APP_API_URL || '',
      NODE_ENV: window._env_?.NODE_ENV,
      REACT_APP_KEYCLOAK_URL: window._env_?.REACT_APP_KEYCLOAK_URL || '',
      REACT_APP_KEYCLOAK_REALM: window._env_?.REACT_APP_KEYCLOAK_REALM || '',
      REACT_APP_KEYCLOAK_CLIENT_ID:
        window._env_?.REACT_APP_KEYCLOAK_CLIENT_ID || '',
      access_token: '',
    }

    this.configuration = this.isValidRunTimeConfig(runtimeConfiguration)
      ? runtimeConfiguration
      : compiletimeConfiguration
  }

  set compiletimeConfiguration(configuration: Partial<IConfiguration>) {
    this.configuration = {
      ...this.configuration,
      ...configuration,
    }
  }

  get config(): IConfiguration {
    return {
      ...this.configuration,
    }
  }

  private isValidRunTimeConfig = (c: IConfiguration) => c && c.REACT_APP_API_URL
}

export default new ConfigService()
