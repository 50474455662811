import { useAuth } from '../auth/useAuth'
import { getRouteData, getRoutes } from './routes'

export const useRoutes = () => {
  const { authenticated } = useAuth()

  const routes = getRoutes().filter((route) => route.public || authenticated)

  return {
    routes,
    getRouteData,
  }
}
