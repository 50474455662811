import { mergeDeepLeft } from 'ramda'
import { RequestState } from './types'
import configService from '../ConfigService/ConfigService'

export const hasBody = (body: any) => (body ? body : {})

export const baseUrl = (url: string) =>
  !url.startsWith('http')
    ? {
        url: `${configService.config.REACT_APP_API_URL}${url}`,
      }
    : { url }

// Directly forcing a 'multipart/form-data' will not automatically generate boundaries, if file is provided, dont set content-type and content-type and boundaries will automatically be generated
export const isMultipart = (multipart: boolean) =>
  multipart ? {} : { 'Content-type': 'application/json' }

// Handle HTTP errors since fetch won't.
export const handleResponse = async (response: Response) => {
  if (response.ok) {
    const data = await response.text()
    return Promise.resolve(data ? JSON.parse(data) : {})
  }
  return Promise.reject(await response.json())
}

export const withHeaders = async ({
  url,
  options,
  authToken,
  memberId,
  multipart,
}: {
  multipart?: boolean
  url: string
  options: RequestInit
  authToken?: string
  memberId?: string
}) => {
  const headers: any = isMultipart(multipart ? true : false)
  if (authToken) {
    headers['Authorization'] = `Bearer ${authToken}`
  }

  const optionsWithHeaders = mergeDeepLeft(options, {
    headers,
  })

  return {
    url,
    options: optionsWithHeaders,
  }
}

export const getInitialRequestState = (): RequestState => ({
  status: 'idle',
  error: undefined,
  message: undefined,
})
