import React from 'react'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import keycloak, { initOptions } from './keycloak'

export type AuthProviderProps = {
  children: React.ReactNode
  showInitializationSpinner?: boolean
}

const AuthProvider = ({
  children,
  showInitializationSpinner = true,
}: AuthProviderProps) => {
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={initOptions}
      LoadingComponent={showInitializationSpinner ? <div /> : undefined}
    >
      {children}
    </ReactKeycloakProvider>
  )
}

export default AuthProvider
