import { KeycloakInstance } from 'keycloak-js'
import { useEffect, useState } from 'react'

import { useKeycloak } from '@react-keycloak/web'

import { AuthUser } from './IAuth'

export interface AuthInstance extends Partial<KeycloakInstance> {
  user: any
}

export const useAuth = (): AuthInstance => {
  const { keycloak } = useKeycloak()

  const [user, setUser] = useState<any>()

  useEffect(() => {
    if (keycloak) {
      keycloak.onTokenExpired = () => keycloak.updateToken(600)
    }
    return () => {
      if (keycloak) keycloak.onTokenExpired = () => {}
    }
  }, [keycloak])

  useEffect(() => {
    if (!keycloak?.idTokenParsed) return

    const user = getUser(keycloak.idTokenParsed)
    setUser(user)
  }, [keycloak?.idTokenParsed])

  return {
    ...keycloak,
    user,
  }
}

function getUser(token?: any) {
  if (!token) return {}

  const user: AuthUser = {
    email: token.email,
    familyName: token.family_name,
    givenName: token.given_name,
    name: token.name,
    username: token.preferred_username,
  }

  return user
}
