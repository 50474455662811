import { Navbar } from '@coopbetala/coop-digital-react-ui'
import React from 'react'
import { Outlet } from 'react-router-dom'
import { Logo } from '../assets/images'
import styles from './Layout.module.scss'

const Layout: React.FunctionComponent = () => {
  return (
    <main>
      <Navbar as="header" className={styles.header}>
        <Logo />
      </Navbar>
      <div className={styles.content}>
        <Outlet />
      </div>
    </main>
  )
}
export default Layout
