import React, { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import './App.css'
import Layout from '../Layout'
import { useRoutes } from '../routes/useRoutes'
import Loading from '../Loading'

const App: React.FunctionComponent = () => {
  const { routes } = useRoutes()

  return (
    <Routes>
      <Route element={<Layout />}>
        {routes.map(({ id, path, component: Component, componentProps }) => (
          <Route
            key={id}
            path={path}
            element={
              <Suspense fallback={<Loading />}>
                {Component && <Component {...componentProps} />}
              </Suspense>
            }
          />
        ))}
      </Route>
    </Routes>
  )
}

export default App
