import { handleResponse } from './Helpers'

const thunkHandler = async (asyncFn: Promise<any>, thunkAPI: any) => {
  try {
    const response = await asyncFn

    const json = await handleResponse(response)
    return json
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error)
  }
}

export { thunkHandler }
