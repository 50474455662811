import React from 'react'

import styles from './Loading.module.scss'

const Loading: React.FunctionComponent = () => (
  <>
    <div className={styles.Loader}>
      <div className={styles['Loader-wrapper']}>
        <div className={styles['Loader-icon']}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </>
)

export default Loading
