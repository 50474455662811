import { includes } from 'ramda'

import { IRequestArgs } from './types'
import { withHeaders, baseUrl } from './Helpers'
import keycloak from '../../auth/keycloak'

const fetchArguments = async ({ url, multipart, ...options }: IRequestArgs) => {
  if (includes(options.method, ['POST', 'PUT', 'PATCH'])) {
    options = {
      ...options,
      body: multipart ? options.body : JSON.stringify(options.body),
    }
  }
  return await withHeaders({
    url: { ...baseUrl(url) }.url,
    multipart,
    options,
    authToken: keycloak.token,
  })
}
export const fetchRequest = async ({
  _fetch = fetch,
  ...args
}: IRequestArgs) => {
  const { url, options } = await fetchArguments(args)
  return await _fetch(url, options)
}

export const baseRequest =
  ({ method }: { method: any }) =>
  async ({ url, ...request }: { url: string; body?: any }) => {
    const fetchRequestConfig = { method, url, ...request }
    const response: any = await fetchRequest(fetchRequestConfig)
    return response
  }
