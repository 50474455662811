import { combineReducers } from '@reduxjs/toolkit'
import ReleaseRequestSlice from '../../ReleaseRequest/ReleaseRequest.slice'

import store from './store'

const rootReducer = () =>
  combineReducers({
    releaseRequest: ReleaseRequestSlice,
  })

export type RootState = ReturnType<typeof store.getState>

export default rootReducer
