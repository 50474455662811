import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { thunkHandler } from '../Infrastructure/Network/api'
import { getInitialRequestState } from '../Infrastructure/Network/Helpers'
import { get, post } from '../Infrastructure/Network'
import { IErrorResponse, RequestState } from '../Infrastructure/Network/types'

export const createReleaseRequest = createAsyncThunk(
  'releaseRequest/createReleaseRequest',
  async (id: string, thunkAPI) => {
    const response = await thunkHandler(
      post({ url: `/member/release-request/v1/${id}` }),
      thunkAPI
    )
    return response
  }
)

export const fetchReleaseRequest = createAsyncThunk(
  'releaseRequest/fetchReleaseRequest',
  async (_ = undefined, thunkAPI) => {
    const response = await thunkHandler(
      get({ url: `/member/release-request/v1` }),
      thunkAPI
    )
    return response
  }
)

const getInitialRequestStates = () => {
  return {
    createReleaseRequest: getInitialRequestState(),
    fetchReleaseRequest: getInitialRequestState(),
  }
}
type ReleaseRequestStatus =
  | 'REQUEST'
  | 'PROCESSED'
  | 'TIMED_OUT'
  | 'NO_REQUEST'
  | 'NO_QR'
  | 'NOT_MEMBER'
  | 'UNDERAGED_MEMBER'
  | 'IDENTIFIER_NOT_FOUND'
  | 'UNIT_NOT_ACTIVE'
  | undefined
const initialState: {
  status: ReleaseRequestStatus
  requestStates: {
    createReleaseRequest: RequestState
    fetchReleaseRequest: RequestState
  }
} = {
  status: undefined,
  requestStates: getInitialRequestStates(),
}
const releaseRequestSlice = createSlice({
  name: 'releaseRequest',
  initialState,
  reducers: {
    resetRequestStates: (state) => {
      state.requestStates = getInitialRequestStates()
      state.status = undefined
    },
    setReleaseStatus: (state, action: PayloadAction<ReleaseRequestStatus>) => {
      state.status = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createReleaseRequest.pending, (state) => {
      state.requestStates.createReleaseRequest.status = 'pending'
    })
    builder.addCase(createReleaseRequest.fulfilled, (state) => {
      state.requestStates.createReleaseRequest = {
        status: 'succeeded',
        error: undefined,
      }
    })
    builder.addCase(createReleaseRequest.rejected, (state, action) => {
      const errorResponse = action.payload as IErrorResponse
      state.status = errorResponse.errorCode as any
      /* if (errorCode === 404) {
        state.status = 'NO_QR'
      } */
      state.requestStates.createReleaseRequest = {
        status: 'failed',
        error: errorResponse,
      }
    })
    builder.addCase(fetchReleaseRequest.pending, (state) => {
      state.requestStates.fetchReleaseRequest.status = 'pending'
    })
    builder.addCase(
      fetchReleaseRequest.fulfilled,
      (state, action: PayloadAction<{ status: ReleaseRequestStatus }>) => {
        state.requestStates.fetchReleaseRequest.status = 'succeeded'
        state.status = action.payload.status
      }
    )
    builder.addCase(fetchReleaseRequest.rejected, (state, action) => {
      const errorCode = action.payload as number
      state.requestStates.fetchReleaseRequest = {
        status: 'failed',
        error: errorCode,
      }
    })
  },
})

export const { setReleaseStatus, resetRequestStates } =
  releaseRequestSlice.actions

export default releaseRequestSlice.reducer
