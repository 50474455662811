import { createHashHistory } from 'history'

import { configureStore } from '@reduxjs/toolkit'

import rootReducer from './rootReducer'

export const history = createHashHistory({})

const store = configureStore({
  reducer: rootReducer(),
  devTools: process.env.NODE_ENV !== 'production',
})

export type AppDispatch = typeof store.dispatch
export type ReduxStore = typeof store

export default store
